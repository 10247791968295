import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/styles'
import SiteDesktopMenu from 'tcweb-material-components/core/siteMenu/desktop'
import SiteMobileMenu from 'tcweb-material-components/core/siteMenu/mobile'
import Icon from 'tcweb-material-components/core/icon'
import Image from 'tcweb-material-components/core/image'
import Box from 'tcweb-material-components/core/box'
import get from 'tcweb-material-components/core/poly/get'
import loadable from '@loadable/component'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import useLogo from '../../hooks/useLogo'
import MenuItem from './components/menuItem'
import SiteMobileMenuItem from './components/siteMobileMenuItem'
import SiteMenuItem from './components/siteMenuItem'
import MenuImage from './menuImage'

const ProfilePopup = loadable(() => import('./components/profilePopup'))
const PushNotificationButton = loadable(() => import('../common/pushNotificationButton'))

const LOGIN_ENABLED = !!process.env.GATSBY_FEATURE_LOGIN_ON && JSON.parse(process.env.GATSBY_FEATURE_LOGIN_ON.toLowerCase()) === true
const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const useClasses = makeStyles(({ palette, breakpoints, accessibility: { highContrast } }) => ({
    root: {
        borderBottom: `1px solid ${palette.border.primary}`,
        [highContrast.mediaQuery('active')]: {
            borderBottom: `1px solid ${palette.border.primary}`
        }
    },
    logo: {
        height: '35px',
        [breakpoints.up('lg')]: { height: '55px' }
    },
    logoLink: {
        display: 'flex',
        marginRight: '30px',
        width: 'initial',
        [breakpoints.up('lg')]: { marginRight: '115px' }
    },

    img: {
        marginBottom: '1em',
        borderRadius: '3%'
    },
    icon: {
        paddingBottom: '.1em',
        height: '30px'
    },
    iconTitle: { fontSize: '0.9em' }
}))

const getItemChildren = item => get(item, 'childItems.nodes')

export default ({ menu: { desktop, mobile } }) => {
    const { t } = useTranslation()
    const classes = useClasses()
    const logo = useLogo('gearsOfWarFull')
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('xs'))
    const iconSize = isMobile ? '24px' : '30px'

    const renderBrand = () => (
        <MenuItem href='/' aria-label={t('slug:go-to-sitename-home', { siteName: process.env.GATSBY_SITE_NAME })} classes={{ root: classes.logoLink }}>
            <Image
                src={logo}
                className={classes.logo}
                alt={t('slug:site-logo', { siteName: process.env.GATSBY_SITE_NAME })}
            />
        </MenuItem>
    )

    const renderMenuItemImage = (item, opts = {}) =>
        <>
            {get(item, 'acf.iconImage')
                ? <MenuImage
                    className={classes.img}
                    fluidImg={get(item, 'acf.iconImage.localFile.childImageSharp.gatsbyImageData')}
                />
                : get(item, 'acf.iconBase', '').toLowerCase() !== 'none' && (
                    <div className={opts.className}>
                        <Icon
                            className={classes.icon}
                            isActive={opts.isActive}
                            iconBase={get(item, 'acf.iconBase', '').toLowerCase()}
                            iconPrefix={get(item, 'acf.iconPrefix', '').toLowerCase()}
                            iconName={get(item, 'acf.iconName', '').toLowerCase()}
                            size='2x'
                        />
                    </div>
                )
            }
            <span className={classes.iconTitle}>{t(get(item, 'label', get(item, 'title')))}</span>
        </>

    return (
        <>
            <SiteDesktopMenu
                classes={{ root: classes.root }}
                items={desktop}
                renderBrand={renderBrand}
                ButtonComponent={SiteMenuItem}
                SkipMenuButtonProps={{ title: t('Skip navigation') }}
                enableSkipMenuButton
            >
                <Box display='flex' alignItems='center'>
                    {PUSH_NOTIFICATION_ENABLED && (
                        <PushNotificationButton
                            marginRight={{ xs: '10px', sm: '20px' }}
                            FontAwesomeIconProps={{ style: { height: iconSize } }}
                        />
                    )}
                    {/* {LOGIN_ENABLED && <ProfilePopup />} */}
                    {/* <Desktop>
                        <Link variant='contained' fullHeight>
                            Primary Button
                        </Link>
                    </Desktop> */}
                </Box>
            </SiteDesktopMenu>

            <SiteMobileMenu
                items={mobile}
                renderMenuItemImage={renderMenuItemImage}
                getItemChildren={getItemChildren}
                MenuItemProps={{ component: SiteMobileMenuItem }}
                ButtonProps={{ component: SiteMobileMenuItem }}
                SlideDrawerProps={{
                    MenuItemProps: { component: SiteMobileMenuItem }
                }}
            />
        </>
    )
}
