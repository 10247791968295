import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
import TCMenuItem from 'tcweb-material-components/core/siteMenu/menuItem'
import get from 'tcweb-material-components/core/poly/get'

import Link from '../../common/link'

const translateStrings = (element, t) =>
	React.Children.map(element, (child) => {
		if (isString(child)) return t(child)

		if (!get(child, 'props.children')) return child

		return React.cloneElement(child, {
			...child.props,
			children: translateStrings(child.props.children, t)
		})
	})

const MenuItem = forwardRef(({ children, ...rest }, ref) => {
	const { t } = useTranslation()
	const [isActive, setIsActive] = useState(false)

	const getProps = ({ isCurrent }) => {
		setIsActive(isCurrent)
		return null
	}

	return (
		<TCMenuItem
			{...rest}
			color='primary'
			isActive={isActive}
			getProps={getProps}
			component={Link}
			ref={ref}
		>
			{translateStrings(children, t)}
		</TCMenuItem>
	)
})

export default MenuItem
