import { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import gearsOfWarLogoWhite from '../assets/images/logos/gearsOfWar-white.svg'
import gearsOfWarLogoBlack from '../assets/images/logos/gearsOfWar.svg'

import gearsOfWarLogoFullWhite from '../assets/images/logos/gearsOfWar-full-white.svg'
import gearsOfWarLogoFullBlack from '../assets/images/logos/gearsOfWar-full.svg'

import theCoalitionLogoFullWhite from '../assets/images/logos/theCoalition-full-white.svg'
import theCoalitionLogoFullBlack from '../assets/images/logos/theCoalition-full.svg'

import xboxLiveWhite from '../assets/images/logos/xboxLive-white.svg'
import xboxLiveBlack from '../assets/images/logos/xboxLive.svg'

/**
 * Logo Names:
 *
 * Gears of War: gearsOfWar / gearsOfWarFull (with omen)
 * The Coalition Studio: theCoalition
 *
 * Theme: light/dark
 */

const LOGOS = {
    gearsOfWar: { logoWhite: gearsOfWarLogoWhite, logoBlack: gearsOfWarLogoBlack },
    gearsOfWarFull: { logoWhite: gearsOfWarLogoFullWhite, logoBlack: gearsOfWarLogoFullBlack },
    theCoalition: {
        logoWhite: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/theCoalition-white-5f2b1be4bddeb.svg',
        logoBlack: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/theCoalition-5f2b1be957e4c.svg'
    },
    theCoalitionFull: { logoWhite: theCoalitionLogoFullWhite, logoBlack: theCoalitionLogoFullBlack },
    xboxGameStudios: {
        logoWhite: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/XboxGameStudios_2020_hrz_Wht-5f2b1addc7aa9.svg',
        logoBlack: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/XboxGameStudios_2020_hrz_Grn-5f2b1ad431c06.svg'
    },
    xboxLive: { logoWhite: xboxLiveWhite, logoBlack: xboxLiveBlack },
    splashDamage: {
        logoWhite: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/splashDamage-white-5f2c2964c0bc7.svg',
        logoBlack: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/splashDamage-5f2c295f7b2dc.svg'
    }
}

const DEFAULT_OPTIONS = {
    enableContrast: true,
    theme: null
}

const useLogo = (name = 'webstarter', options = DEFAULT_OPTIONS) => {
    const currentTheme = useTheme()
    const _options = { ...DEFAULT_OPTIONS, ...options }
    const _theme = _options.theme || currentTheme.palette.type
    const defaultLogo = _theme === 'light' ? LOGOS[name].logoBlack : LOGOS[name].logoWhite
    const [logo, setLogo] = useState(defaultLogo)
    const isHighContrast = useMediaQuery(currentTheme.accessibility.highContrast.mediaQuery('active'))
    const isHighContrastWB = useMediaQuery(currentTheme.accessibility.highContrast.prefersDark)
    const isHighContrastBW = useMediaQuery(currentTheme.accessibility.highContrast.prefersLight)

    useEffect(() => {
        if (_theme === 'light') {
            if ((isHighContrastWB || isHighContrast) && !isHighContrastBW && _options.enableContrast) {
                setLogo(LOGOS[name].logoWhite)
            } else {
                setLogo(LOGOS[name].logoBlack)
            }
        } else {
            if (isHighContrastBW && _options.enableContrast) {
                setLogo(LOGOS[name].logoBlack)
            } else {
                setLogo(LOGOS[name].logoWhite)
            }
        }
    }, [isHighContrastBW, isHighContrastWB, isHighContrast, _options.enableContrast, name, _theme])

    return logo
}

export default useLogo
